
// try {
//     window.$ = window.jQuery = require('../libs/jquery.min.js');

// } catch (e) { }




require('../libs/jquery.validate.min.js');
require('../libs/jquery.form.min.js');
require('../libs/TweenMax.min.js');
require('../libs/select2.js');
require('../libs/main.js');
require('../libs/jquery.isotope.min.js');
require('../libs/owl.carousel.min.js');
require('../libs/jquery-ui.min.js');
require('../libs/jquery.fancybox.pack.js');
require('../libs/jquery.fancybox-media.js');
require('../libs/retina.min.js');